import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import { CREATE_SELECTION_ITEM_MUTATION } from '../../../graphql/mutations'
import { SELECTIONS_QUERY } from '../../../graphql/queries'
import { getStoredCasesColumns } from '../../../utils/helper'
import { useSnackbar } from '../../../utils/hooks'
import { ColumnSelectionItem, Input, Button } from '../../atoms'

interface ColumnSelectionProps {
  onSave: (columns: any, title: string) => void
  shared?: boolean
  editable?: boolean
  deleteable?: boolean
  isAdmin?: boolean
}

const ColumnSelection: React.FC<ColumnSelectionProps> = ({
  onSave,
  shared,
  editable,
  isAdmin,
  deleteable
}) => {
  const { t } = useTranslation(['casesOverview', 'common'])
  const [newTitle, setNewTitle] = useState('')
  const { setSnackbar } = useSnackbar()

  const { data, error, refetch } = useQuery(SELECTIONS_QUERY, {
    variables: { shared },
    fetchPolicy: 'cache-and-network'
  })
  const [createSelectionMutation] = useMutation(CREATE_SELECTION_ITEM_MUTATION)

  const onSubmit = () => {
    if (!newTitle || newTitle.length === 0) {
      setSnackbar({
        open: true,
        text: t('nameViewError'),
        variant: 'error'
      })
    } else {
      const columns = getStoredCasesColumns()
      createSelectionMutation({
        variables: {
          attributes: {
            title: newTitle,
            columns: JSON.stringify(columns)
          }
        },
        onCompleted: refetch
      })
      setNewTitle('')
    }
  }

  const renderCreateSelection = () => (
    <>
      <Typography variant="h5">{t('saveViewTitle')}</Typography>
      <p>{t('saveViewInfo')}</p>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 1,
          marginBottom: 4
        }}
      >
        <Box sx={{ minWidth: '80%' }}>
          <Input
            fullWidth
            value={newTitle}
            onChange={e => setNewTitle(e.target.value)}
            label={t('nameView')}
          />
        </Box>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          {t('common:save')}
        </Button>
      </Box>
    </>
  )

  if (error) throw error
  return (
    <Box>
      {!shared && renderCreateSelection()}
      <Typography variant="h5">{t('selectView')}</Typography>
      <Box mt={1}>
        {data?.columnSelections?.collection?.map((selection: any) => (
          <ColumnSelectionItem
            isAdmin={isAdmin}
            editable={editable}
            deleteable={deleteable}
            onSelect={onSave}
            onDelete={refetch}
            refetch={refetch}
            item={selection}
            key={selection.title}
          />
        ))}
      </Box>
    </Box>
  )
}
ColumnSelection.propTypes = {
  onSave: PropTypes.func.isRequired
}

export default ColumnSelection
