import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import {
  Assessment,
  FormatListBulleted,
  OpenInNewOutlined,
  Person,
  PhoneCallback,
  PowerSettingsNewSharp,
  SettingsSharp,
  WarningAmberSharp
} from '@mui/icons-material'
import { Box, ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import moment from 'moment'
import { HouseIcon, ListIcon, InfoBulb, Hermine } from '../assets/icons'
import client from '../client'
import { SideBar, BrandLogo } from '../components/atoms'
import { TopBar, MenuList, UserCard, MobileMenu } from '../components/molecules'
import config from '../config'
import {
  getToken,
  getUid,
  getClient,
  getExpiry,
  clearUserData,
  setTimer,
  getDisabledMenuItems,
  setSidebarOpen,
  getSidebarOpen
} from '../utils/helper'
import { useProfile, useUser } from '../utils/hooks'
import TitleContext from './TitleContext'

interface CustomerHeadersInit extends Headers {
  'access-token': string
  uid: string
  client: string
}

interface ContextWrapperProps {
  children?: React.ReactNode
}

// CK - NOTE: 1px smaller as in SideBar component
const drawerWidth = 380
const drawerWidthClosed = 60
const topBarHeight = 142
const mediatorInfoEnabled = process.env.REACT_APP_MEDIATOR_INFO_ENABLED === 'true'
const profileEnabled = process.env.REACT_APP_PROFILE_ENABLED === 'true'
const mediationKIUrl = process.env.REACT_APP_MEDIATION_KI_URL
const legalAdviceKIUrl = process.env.REACT_APP_LEGAL_ADVICE_KI_URL

const blinkingText = {
  '@keyframes blinker': {
    '50%': {
      opacity: 0
    }
  },
  animation: 'blinker 1s linear infinite'
}

const ContextWrapper: React.FC<ContextWrapperProps> = ({ children }) => {
  const { t } = useTranslation('common')
  const history = useHistory()
  const [open, setOpen] = useState(() => getSidebarOpen() ?? true)
  useEffect(() => {
    setSidebarOpen(open)
  }, [open])
  const {
    values: { title, subTitle, extra }
  } = useContext(TitleContext)

  const {
    user: { name, role },
    isEditor,
    isAdmin
  } = useUser()

  const { me, refetch, setMe } = useProfile()

  useEffect(() => {
    if (me && Object.keys(me).length === 0) {
      refetch()
    }
  }, [me, refetch])
  const theme = useTheme() as Theme
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const landscape = useMediaQuery('(orientation: landscape)')
  const logout = () => {
    fetch(`${config.apollo.baseUrl}/auth/sign_out`, {
      method: 'GET',
      headers: {
        'access-token': getToken(),
        uid: getUid(),
        client: getClient()
      } as CustomerHeadersInit
    }).then(res => {
      if (res.ok) {
        const expiry = getExpiry()
        setTimer(
          JSON.stringify({
            last_expiry: moment.unix(expiry ? Number(expiry) : 0).toLocaleString(),
            date: moment().toLocaleString(),
            message: 'logged out by User'
          })
        )
        setMe(null)
        clearUserData()
        client.clearStore()
        history.push('/')
      }
    })
  }

  const menuItems = [
    {
      permissionId: 'dashboard',
      icon: <HouseIcon />,
      label: t('dashboard'),
      isNavItem: true,
      to: '/dashboard'
    },
    ...(['user', 'legal-advisor'].includes(role)
      ? [
          {
            permissionId: 'tasks',
            icon: <FormatListBulleted />,
            label: t('tasks'),
            isNavItem: true,
            to: '/tasks'
          }
        ]
      : []),
    {
      permissionId: 'evaluation-rb',
      icon: <Assessment />,
      label: t('evaluationRB'),
      isNavItem: true,
      to: '/evaluation-rb'
    },
    {
      permissionId: 'cases',
      icon: <Person />,
      label: isAdmin ? t('casesOverview') : t('myCases'),
      isNavItem: true,
      to: '/cases',
      ...(!isEditor && { additionalHref: '/cases/new' })
    },
    {
      permissionId: 'cases-creator',
      icon: <ListIcon />,
      label: t('casesOverview'),
      isNavItem: true,
      to: '/cases-creator'
    },
    ...(role !== 'creator'
      ? (me?.pools || []).map((pool: any) => ({
          permissionId: pool.name,
          icon: <PhoneCallback />,
          label: pool.menuTitle,
          isNavItem: true,
          to: `/pools/${pool.slug}`
        })) || []
      : []),
    ...(mediatorInfoEnabled
      ? [
          {
            permissionId: 'mediatorInfo',
            icon: <InfoBulb />,
            label: t('mediatorInfo'),
            isNavItem: true,
            to: '/mediator-info?tab=home'
          }
        ]
      : []),
    ...(legalAdviceKIUrl && me?.aiAvailable
      ? [
          {
            permissionId: 'ki-rechtsberatung',
            icon: <Hermine />,
            suffixIcon: <OpenInNewOutlined />,
            label: t('kiRechtsberatung'),
            isNavItem: false,
            externalLink: true,
            to: legalAdviceKIUrl
          }
        ]
      : []),
    ...(mediationKIUrl && me?.aiAvailable
      ? [
          {
            permissionId: 'ki-mediation',
            icon: <Hermine />,
            suffixIcon: <OpenInNewOutlined />,
            label: t('kiMediation'),
            isNavItem: false,
            externalLink: true,
            to: mediationKIUrl
          }
        ]
      : [])
  ]

  const getUserCard = () => {
    let ret = <></>
    if (me || name) {
      ret = (
        <UserCard
          user={{
            imageSrc: me?.avatar?.url || undefined,
            name: me?.name || name,
            role: (me && t(me?.role)) || t(role)
          }}
          logout={logout}
          open={open}
          {...(profileEnabled && {
            onClick: () =>
              history.push('/profile', {
                from: history.location.pathname,
                search: history.location.search
              })
          })}
        />
      )
    }
    return ret
  }
  return (
    <Box>
      {me?.away && mobile && (
        <Box
          sx={{
            position: 'fixed',
            ...blinkingText,
            zIndex: 999,
            width: '100%',
            color: '#ff0000',
            textAlign: 'center',
            padding: '20px',
            pointerEvents: 'none',
            fontSize: '1.5rem',
            backgroundColor: 'rgba(255, 255, 255, 0.1)'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <WarningAmberSharp
              sx={{
                height: '20px',
                width: '20px'
              }}
            />
            <Box
              component="span"
              sx={{
                paddingLeft: '10px',
                fontWeight: 400
              }}
            >
              {t('awayModeActive')}
            </Box>
          </Box>
        </Box>
      )}

      <TopBar
        title={title}
        subTitle={subTitle}
        extra={extra}
        position={mobile ? 'relative' : 'fixed'} // MF: "MUI doc: 'sticky' not IE11 compatible"
        zIndex={980}
        {...(mobile
          ? { marginLeft: '0px', width: '100%' }
          : {
              marginLeft: open ? `${drawerWidth}px` : `${drawerWidthClosed}px`,
              width: open ? `calc(100% - ${drawerWidth}px)` : `calc(100% - ${drawerWidthClosed}px)`
            })}
        height={`${topBarHeight}px`}
      />

      {!mobile ? (
        <SideBar
          open={open}
          setOpen={setOpen}
          drawerWidth={drawerWidth}
          drawerWidthClosed={drawerWidthClosed}
        >
          <Box
            sx={{
              width: open ? `${drawerWidth}px` : `${drawerWidthClosed}px`,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%'
            }}
          >
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  padding: open ? '40px' : '40px 20px',
                  justifyContent: open ? 'start' : 'center'
                }}
              >
                <BrandLogo
                  width={open ? '300px' : undefined}
                  height={!open ? '73px' : undefined}
                  type={open ? 'default' : 'vertical'}
                />
              </Box>
              {me?.away && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    '@keyframes blinker': {
                      '50%': {
                        opacity: 0
                      }
                    },
                    animation: 'blinker 1s linear infinite'
                  }}
                >
                  <ListItemButton
                    component="span"
                    sx={{
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      paddingLeft: open ? '37px' : '20px',
                      minHeight: '20px',
                      fontWeight: 400
                    }}
                    onClick={() => {
                      history.push('/profile', {
                        from: history.location.pathname,
                        search: history.location.search
                      })
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        paddingRight: '12px',
                        color: '#ff0000'
                      }}
                    >
                      <WarningAmberSharp sx={{ height: '20px', width: '20px' }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={t('awayModeActive')}
                      disableTypography
                      sx={{
                        display: open ? '' : 'none',
                        color: '#ff0000',
                        fontWeight: 'typography.fontWeightBold',
                        margin: 0,
                        lineHeight: '20px',
                        fontSize: '1.5rem'
                      }}
                    />
                  </ListItemButton>
                </Box>
              )}

              <MenuList
                menuItems={menuItems}
                defaultActive={0}
                open={open}
                onChange={() => {}}
                disabled={getDisabledMenuItems(role)}
              />
            </Box>
            {getUserCard()}
          </Box>
        </SideBar>
      ) : (
        <MobileMenu
          items={menuItems}
          landscape={landscape}
          disabled={getDisabledMenuItems(role)}
          actions={[
            {
              name: t('logout'),
              onClick: logout,
              icon: <PowerSettingsNewSharp />
            },
            ...(profileEnabled
              ? [
                  {
                    name: t('profile'),
                    onClick: () =>
                      history.push('/profile', {
                        from: history.location.pathname,
                        search: history.location.search
                      }),
                    icon: <SettingsSharp />
                  }
                ]
              : [])
          ]}
        />
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: 'fit-content',
          overflow: 'auto',
          marginLeft: `${open ? drawerWidth : drawerWidthClosed}px`,
          ...(mobile && { marginLeft: '0px' }),
          px: 4,
          pb: 4,
          pt: `${mobile ? 0 : topBarHeight + 32}px` // MF: Offset for fixed topbar
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default ContextWrapper
