import React from 'react'
import { Box, FormHelperText, SxProps } from '@mui/material'
import CustomLabel from './CustomLabel'
import CustomSwitch, { CustomSwitchProps } from './CustomSwitch'

export interface SwitchProps extends CustomSwitchProps {
  defaultChecked?: boolean
  disabled?: boolean
  value?: any
  checked?: any
  label?: string
  small?: boolean
  labelSx?: SxProps
  helperText?: string
  error?: string
  sx?: SxProps
}

const Switch = React.forwardRef<HTMLButtonElement, SwitchProps>(
  (
    {
      defaultChecked = undefined,
      onChange = () => {},
      disabled = false,
      value = '',
      checked = undefined,
      label = '',
      small = false,
      labelSx = null,
      error,
      helperText,
      sx
    },
    ref
  ) => (
    <Box sx={sx}>
      <CustomLabel
        sx={labelSx}
        control={
          <CustomSwitch
            onChange={onChange}
            defaultChecked={defaultChecked}
            disabled={disabled}
            ref={ref}
            value={value}
            checked={checked}
            small={small}
          />
        }
        label={label}
      />
      <FormHelperText error={!!error}>{helperText}</FormHelperText>
    </Box>
  )
)

export default Switch
