import React, { Dispatch, SetStateAction, useState } from 'react'
import Snackbar, { IMessage } from '../../components/atoms/Snackbar/Snackbar'

const initialValues = {
  snackbar: {
    open: false,
    text: '',
    variant: 'error'
  },
  refetch: () => {}
}

type ContextType = {
  snackbar: IMessage
  setSnackbar: Dispatch<SetStateAction<IMessage>>
}
const context = {
  snackbar: initialValues.snackbar,
  setSnackbar: () => {}
} as ContextType

const SnackbarContext = React.createContext<ContextType>(context)
interface ProfilContextProviderProps {
  children: JSX.Element
}

export const SnackbarContextProvider = ({ children }: ProfilContextProviderProps) => {
  const [snackbar, setSnackbar] = useState<any>(initialValues.snackbar)

  return (
    <SnackbarContext.Provider
      value={{
        snackbar,
        setSnackbar
      }}
    >
      {children}
      <Snackbar snackbar={snackbar} setSnackbar={setSnackbar} />
    </SnackbarContext.Provider>
  )
}

export default SnackbarContext
