import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Edit } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Grid,
  Snackbar,
  Alert
} from '@mui/material'
import moment from 'moment'
import { TrashCan } from '../../../assets/icons'
import {
  DELETE_SELECTION_ITEM_MUTATION,
  UPDATE_SELECTION_ITEM_MUTATION
} from '../../../graphql/mutations'
import Button from '../Button'
import Divider from '../Divider'
import Input from '../Input'
import Select from '../Select'

export interface ColumnSelectionItemProps {
  item: {
    id: string
    title: string
    createdAt: string
    sharedWith: string[]
    user: {
      name: string
    }
    columns: string
  }
  onDelete: () => void
  onSelect: (columns: any, title: string) => void
  onUpdate?: () => void
  editable?: boolean
  deleteable?: boolean
  isAdmin?: boolean
  refetch?: () => void
}

const sharedWithItems = [
  { label: 'Admins', value: 'Admin' },
  { label: 'Bearbeiter', value: 'Bearbeiter' },
  { label: 'Erfasser', value: 'Erfasser' },
  { label: 'Rechtsberater', value: 'Rechtsberater' }
]

const ColumnSelectionItem: React.FC<ColumnSelectionItemProps> = ({
  item,
  onDelete,
  onSelect,
  onUpdate,
  editable,
  deleteable,
  refetch,
  isAdmin
}) => {
  const [snackbar, setSnackbar] = useState<{
    visible: boolean
    type?: 'error' | 'success'
    message?: string
  }>({
    visible: false,
    type: 'error'
  })
  const closeSnackbar = () => setSnackbar({ visible: false })
  const { t } = useTranslation('common')
  const [modalOpen, setModalOpen] = useState(false)
  const [updateSelectionItem] = useMutation(UPDATE_SELECTION_ITEM_MUTATION, {
    onCompleted: onUpdate
  })
  const [deleteSelectionItem] = useMutation(DELETE_SELECTION_ITEM_MUTATION, {
    onCompleted: onDelete
  })
  const defaultValues = isAdmin
    ? {
        sharedWith: item.sharedWith || [],
        title: item.title
      }
    : { title: item.title }
  const { handleSubmit, control } = useForm({ defaultValues })
  const onUpdateConfirm = (values: any) => {
    updateSelectionItem({
      variables: {
        id: item.id,
        attributes: values
      },
      onCompleted: () => {
        setSnackbar({ visible: true, type: 'success', message: t('columnSelectionChanged') })
        setModalOpen(false)
        refetch?.()
      }
    })
  }
  const onDeleteButtonClick = () => {
    deleteSelectionItem({
      variables: {
        id: item.id
      }
    })
  }
  const onClick = () => {
    const columns = JSON.parse(item.columns)
    onSelect(columns, item.title)
  }
  const captionFirstPart = `${moment(item.createdAt).format('DD.MM.YYYY')} - ${item.user?.name}`
  const captionSharedWith = `${t('sharedWith')}: ${item.sharedWith?.filter(i => !!i).join(', ')}`
  const caption =
    item.sharedWith?.filter((i: string) => !!i)?.length > 0
      ? `${captionFirstPart} - ${captionSharedWith}`
      : captionFirstPart
  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box
          tabIndex={0}
          role="link"
          onKeyUp={onClick}
          onClick={onClick}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography>{item.title}</Typography>
          <Typography variant="caption" sx={{ color: 'grey.500' }}>
            {caption}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {editable ? (
            <Box
              sx={{
                cursor: 'pointer',
                padding: 1,
                minWidth: '40px',
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '50%',
                alignSelf: 'center',
                '&:hover': {
                  backgroundColor: 'grey.50'
                }
              }}
            >
              <Edit onClick={() => setModalOpen(true)} />
            </Box>
          ) : null}
          {deleteable ? (
            <Box
              sx={{
                cursor: 'pointer',
                padding: 1,
                minWidth: '40px',
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '50%',
                alignSelf: 'center',
                '&:hover': {
                  backgroundColor: 'grey.50'
                }
              }}
            >
              <TrashCan onClick={onDeleteButtonClick} />
            </Box>
          ) : null}
        </Box>
      </Box>
      <Divider my={6} />
      <Dialog maxWidth="md" open={modalOpen} onClose={() => setModalOpen(false)}>
        <form onSubmit={handleSubmit(onUpdateConfirm)}>
          <DialogTitle>
            <Box
              sx={theme => ({
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }
              })}
            >
              {t('editColumnSelectionItem', { title: item.title })}
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <Box>
                <Controller
                  control={control}
                  name="title"
                  render={({ field }) => <Input {...field} label={t('title')} fullWidth />}
                />
              </Box>
              {isAdmin ? (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Controller
                    control={control}
                    name="sharedWith"
                    render={({ field }) => (
                      <Select
                        {...field}
                        multiple
                        items={sharedWithItems}
                        label={t('sharedWith')}
                        name="sharedWith"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Box>
              ) : null}
            </Box>
          </DialogContent>
          <DialogActions>
            <Grid container gap={2} md="auto" flexDirection="row">
              <Grid item>
                <Button color="secondary" type="button" onClick={() => setModalOpen(false)}>
                  {t('common:abort')}
                </Button>
              </Grid>
              <Grid item>
                <Button id="editor-modal-save-button" type="submit">
                  {t('common:save')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar open={snackbar.visible} onClose={closeSnackbar} autoHideDuration={2000}>
        <Alert onClose={closeSnackbar} severity={snackbar.type === 'success' ? 'info' : 'error'}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default ColumnSelectionItem
