import React, { useEffect, useState } from 'react'
import ExternalDatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import MaskedInput, { Props as MaskedInputProps } from 'react-input-mask'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { InputAdornment, TextField } from '@mui/material'
import { de } from 'date-fns/locale'
import moment from 'moment'
import { getDateFromString } from '../../../utils/helper'
import Wrapper from '../DatePickerWrapper'

export interface DatePickerProps {
  onChange: (date: Date | null) => void
  type: 'date' | 'time' | 'datetime'
  value?: Date | null
  maxDate?: Date | null
  label?: string
  timeIntervals?: number
  timeCaption?: string
  fullWidth?: boolean
  disabled?: boolean
  helperText?: string
  error?: string
}

const DatePicker = React.forwardRef<JSX.Element, DatePickerProps>(
  (
    {
      value: propValue,
      onChange: onPropChange,
      label,
      type,
      timeIntervals,
      timeCaption,
      fullWidth,
      disabled,
      helperText,
      error,
      maxDate
    },
    ref
  ) => {
    const { t } = useTranslation('common')
    const [value, setValue] = useState(() => getDateFromString(propValue))

    useEffect(() => {
      setValue(getDateFromString(propValue))
    }, [propValue])

    const dateHandle = (date: Date | null): Date | null => {
      let ret = date
      if (type === 'date') {
        const mom = moment(date).set({ hours: 12 })
        if (mom.isValid()) ret = mom.toDate()
        else ret = null
      }

      return ret
    }

    const onChange = (date: Date) => {
      if (typeof onPropChange === 'function') {
        // NOTE: hack to ignore european timezones
        setValue(dateHandle(date))
        onPropChange(dateHandle(date))
      } else {
        setValue(dateHandle(date))
      }
    }
    const dateFormat = {
      date: 'dd.MM.yyyy',
      datetime: 'dd.MM.yyyy HH:mm',
      time: 'HH:mm'
    }
    const getMask = (maskType: string) => {
      let mask = '99.99.9999 99:99'
      if (maskType === 'date') mask = '99.99.9999'
      if (maskType === 'time') mask = '99:99'
      return mask
    }

    return (
      <Wrapper $fullWidth={fullWidth}>
        <ExternalDatePicker
          selected={value}
          onChange={onChange}
          locale={de}
          dateFormat={dateFormat[type]}
          showPopperArrow={false}
          showTimeSelect={type !== 'date'}
          showTimeSelectOnly={type === 'time'}
          timeFormat="HH:mm"
          timeIntervals={timeIntervals}
          timeCaption={timeCaption}
          maxDate={maxDate}
          isClearable={!disabled}
          disabled={disabled}
          customInput={
            <MaskedInput mask={getMask(type)}>
              {/* @ts-ignore */}
              {(props: MaskedInputProps & { color: 'primary' | undefined }) => (
                <TextField
                  {...props}
                  label={label}
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      borderRight: '1px solid lightgrey'
                    },
                    '& + .react-datepicker__close-icon': {
                      right: '40px !important',
                      color: 'transparent !important'
                    },
                    '& + .react-datepicker__close-icon:hover:after': {
                      backgroundImage: 'url(/assets/icons/clear_hover.svg)'
                    },
                    '& + .react-datepicker__close-icon::after': {
                      content: '""',
                      backgroundColor: 'transparent',
                      backgroundImage: 'url(/assets/icons/clear.svg)',
                      backgroundPosition: 'center center'
                    }
                  }}
                  fullWidth={fullWidth}
                  inputRef={ref}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {type === 'time' ? t('oclock') : <CalendarTodayIcon fontSize="small" />}
                      </InputAdornment>
                    )
                  }}
                  helperText={helperText}
                  error={!!error}
                  disabled={disabled}
                />
              )}
            </MaskedInput>
          }
        />
      </Wrapper>
    )
  }
)
DatePicker.defaultProps = {
  value: null,
  onChange: () => {},
  label: 'Datum auswählen',
  type: 'date',
  timeIntervals: 15,
  timeCaption: 'Zeit',
  fullWidth: false,
  disabled: false,
  helperText: undefined,
  maxDate: undefined
} as DatePickerProps

export default DatePicker
