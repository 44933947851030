import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { ME_QUERY } from '../../graphql/queries'
import { Processing } from '../fixtures'

const initialValues = {
  me: {},
  refetch: () => {}
}

type ContextType = {
  processing?: Processing
  me?: any
  setMe: Dispatch<SetStateAction<any>>
  refetch: ReturnType<typeof useLazyQuery>[0]
}
const context = {
  me: initialValues.me,
  setMe: () => {},
  refetch: () => new Promise(() => {})
} as ContextType

const ProfilContext = React.createContext<ContextType>(context)
interface ProfilContextProviderProps {
  children: JSX.Element
}

export const ProfilContextProvider = ({ children }: ProfilContextProviderProps) => {
  const [me, setMe] = useState<any>(initialValues.me)

  const [refetch, { data }] = useLazyQuery(ME_QUERY, {
    fetchPolicy: 'network-only'
  })
  useEffect(() => {
    setMe({ ...data?.me })
  }, [data, setMe])

  return (
    <ProfilContext.Provider
      value={{
        me,
        setMe,
        refetch
      }}
    >
      {children}
    </ProfilContext.Provider>
  )
}

export default ProfilContext
