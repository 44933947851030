import i18n from '../Localization'

export const getCaseStates = () => [
  {
    label: i18n.t('caseProperty:inCapture'),
    value: 'in_capture'
  },
  {
    label: i18n.t('caseProperty:open'),
    value: 'open'
  },
  {
    label: i18n.t('caseProperty:coverage_request'),
    value: 'coverage_request'
  },
  {
    label: i18n.t('caseProperty:in_progress'),
    value: 'in_progress'
  },
  {
    label: i18n.t('caseProperty:jump_off'),
    value: 'jump_off'
  },
  {
    label: i18n.t('caseProperty:completed'),
    value: 'completed'
  },
  {
    label: i18n.t('caseProperty:checked'),
    value: 'checked'
  },
  {
    label: i18n.t('caseProperty:settled'),
    value: 'settled'
  }
]
export const getSettlementRateEnum = () => [
  {
    label: '0 %',
    value: 0
  },
  {
    label: '100 %',
    value: 100
  }
]

export const getCommunicationEnum = () => [
  {
    label: i18n.t('caseProperty:notAsked'),
    value: 'not_asked'
  },
  {
    label: i18n.t('common:yes'),
    value: 'yes'
  },
  {
    label: i18n.t('common:no'),
    value: 'no'
  }
]

export const getPaymentStatusEnum = () => [
  {
    label: i18n.t('caseProperty:open'),
    value: 'open'
  },
  {
    label: i18n.t('caseProperty:requested'),
    value: 'requested'
  },
  {
    label: i18n.t('caseProperty:paid'),
    value: 'paid'
  },
  {
    label: i18n.t('caseProperty:reminded'),
    value: 'reminded'
  },
  {
    label: i18n.t('caseProperty:expired'),
    value: 'expired'
  },
  {
    label: i18n.t('caseProperty:error'),
    value: 'error'
  },
  {
    label: i18n.t('caseProperty:canceled'),
    value: 'canceled'
  }
]

export const getAgentRoleEnum = () => [
  {
    label: i18n.t('common:admin'),
    value: 'admin'
  },
  {
    label: i18n.t('common:creator'),
    value: 'creator'
  },
  {
    label: i18n.t('common:user'),
    value: 'user'
  },
  {
    label: i18n.t('common:legal_advisor'),
    value: 'legal_advisor'
  }
]

export const getProcessingStates = () => [
  {
    label: i18n.t('caseProperty:open'),
    value: 'open'
  },
  {
    label: i18n.t('caseProperty:assigned'),
    value: 'assigned'
  },
  {
    label: i18n.t('caseProperty:accepted'),
    value: 'accepted'
  },
  {
    label: i18n.t('caseProperty:blocked'),
    value: 'blocked'
  },
  {
    label: i18n.t('caseProperty:first_contacted'),
    value: 'first_contacted'
  },
  {
    label: i18n.t('caseProperty:coverage_check'),
    value: 'coverage_check'
  },
  {
    label: i18n.t('caseProperty:in_progress'),
    value: 'in_progress'
  },
  {
    label: i18n.t('caseProperty:completed'),
    value: 'completed'
  },
  {
    label: i18n.t('caseProperty:checked'),
    value: 'checked'
  },
  {
    label: i18n.t('caseProperty:settled'),
    value: 'settled'
  },
  {
    label: i18n.t('caseProperty:transfer'),
    value: 'transfer'
  },
  {
    label: i18n.t('caseProperty:jump_off'),
    value: 'jump_off'
  }
]
