import { useState, useRef } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useHistory } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import moment from 'moment'
import config from '../config'
import { ME_QUERY } from '../graphql/queries'
import { getExpiry, getToken, getUid, getClient, clearUserData, setTimer } from './helper'

const TimeOut = () => {
  const history = useHistory()
  const timeOutRef = useRef(null)
  const [lastActive, setLastActive] = useState(moment().unix())

  const [fetchNewRefreshToken] = useLazyQuery(ME_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setLastActive(moment().unix())
    },
    onError: () => {
      setTimer(
        JSON.stringify({
          last_expiry: moment.unix(getExpiry()).toLocaleString(),
          date: moment().toLocaleString(),
          message: 'logged out by me_query'
        })
      )
      logout()
    }
  })

  const getTimeLeft = () => moment.unix(getExpiry()).diff(moment())

  const logout = () => {
    fetch(`${config.apollo.baseUrl}/auth/sign_out`, {
      method: 'GET',
      headers: {
        'access-token': getToken(),
        uid: getUid(),
        client: getClient()
      }
    })
      .then(() => {
        clearUserData()
        history.push('/')
      })
      .catch(() => {
        clearUserData()
        history.push('/')
      })
  }

  const handleOnIdle = () => {
    console.debug('idle')
    timeOutRef.current = setTimeout(() => {
      logout()
      setTimer(
        JSON.stringify({
          last_expiry: moment.unix(getExpiry()).toLocaleString(),
          date: moment().toLocaleString(),
          message: 'loggout by setTimeout'
        })
      )
      console.debug('logging out')
    }, getTimeLeft() - 10000)
  }
  const handleActive = () => {
    console.debug('active')
    if (timeOutRef?.current) {
      clearTimeout(timeOutRef.current)
      console.debug('cleanup')
    }
    if (getTimeLeft() < 10000) {
      setTimer(
        JSON.stringify({
          last_expiry: moment.unix(getExpiry()).toLocaleString(),
          date: moment().toLocaleString(),
          message: 'logout by handleActive'
        })
      )
      logout()
    }
  }
  const handleOnAction = event => {
    if (event.type === 'mousedown' || event.type === 'keydown') {
      console.debug(event.type)
      fetchNewRefreshToken()
    }
    // CK - NOTE: re-assigning expiry in client.js by invoking me-query manually
    if (moment().unix() - lastActive > 60) {
      setLastActive(moment().unix())
      console.debug('refetching...')
      fetchNewRefreshToken()
    }
  }

  useIdleTimer({
    timeout: 1000 * 30, // 30 seconds
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    onActive: handleActive,
    debounce: 1000 * 20, // 20 seconds
    crossTab: true,
    syncTimers: 200
  })
  return null
}

export default TimeOut
