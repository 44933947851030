import { Alert, Snackbar as MSnackbar } from '@mui/material'

export interface IMessage {
  open: boolean
  text?: string
  variant: 'success' | 'error'
  autoHideDuration?: number
}

export interface SnackbarProps {
  snackbar: IMessage
  setSnackbar: React.Dispatch<React.SetStateAction<IMessage>>
}

const Snackbar: React.FC<SnackbarProps> = ({ snackbar, setSnackbar }) => (
  <MSnackbar
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    open={snackbar.open}
    autoHideDuration={snackbar.autoHideDuration || 6000}
    onClose={() => setSnackbar((m: IMessage) => ({ ...m, open: false }))}
    message={snackbar?.text}
  >
    <Alert
      onClose={() => setSnackbar((m: IMessage) => ({ ...m, open: false }))}
      variant="filled"
      severity={snackbar.variant}
      sx={{ width: '100%' }}
    >
      {snackbar.text}
    </Alert>
  </MSnackbar>
)

export default Snackbar
