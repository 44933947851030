import { gql } from '@apollo/client'

export const CUSTOMERS_DUPLICATES_QUERY = gql`
  query customersDuplicatesQuery($id: ID!) {
    duplicateCustomers(id: $id) {
      id
      firstname
      lastname
      policyNumber
      mobileNumber
      phoneNumber
      street
      houseNumber
      email
      duplicateEmail
      possibleDuplicate
      cases {
        id
        claimNumber
      }
    }
  }
`

export const FILES_IN_PROCESSING_QUERY = gql`
  query filesInProcessingQuery($processingId: ID!) {
    uploads(processingId: $processingId) {
      collection {
        id
        attachmentUrl
        attachmentFilename
      }
    }
  }
`

export const GET_PRESIGNED_S3_URL_QUERY = gql`
  query getPresignedS3Url($filename: String!) {
    getPresignedS3Url(filename: $filename)
  }
`

export const TASKS_QUERY = gql`
  query tasks($processingId: ID!) {
    tasks(processingId: $processingId) {
      collection {
        createdAt
        done
        dueDate
        id
        processingId
        title
        updatedAt
      }
    }
  }
`

export const CUSTOMERS_QUERY = gql`
  query customers($page: Int, $limit: Int, $filter: CustomerFilter, $sortBy: CustomerSort) {
    customers(page: $page, limit: $limit, filter: $filter, sortBy: $sortBy) {
      collection {
        id
        salutation
        firstname
        lastname
        birthDate
        company
        birthDate
        email
        street
        houseNumber
        mobileNumber
        phoneNumber
        postalCode
        place
        status
      }
    }
  }
`

export const ME_QUERY = gql`
  query me {
    me {
      id
      firstname
      lastname
      birthDate
      email
      street
      houseNumber
      place
      postalCode
      mobileNumber
      phoneNumber
      name
      aiAvailable
      createdAt
      updatedAt
      role
      pools {
        id
        slug
        name
        menuTitle
        columnDefinitions
      }
      visibleEmployeeList
      avatar {
        url
      }
      portalEmail
      portalPhoneNumber
      portalMobileNumber
      allowJobPdfDownload
      away
      awayMessage
      employeeHohner
      hasCallbacksEnabled
      refundAllowed
    }
  }
`
interface LookupTableSort {
  attribute: 'id' | 'createdAt' | 'updatedAt' | 'lookupType' | 'value' | 'systemValue' | 'sort'
  direction: 'asc' | 'desc'
}

interface LookupTableFilter {
  id: 'string'
  value: string
  systemValue: string
  sort: number
  lookupType: string
  lookupTypes: string[]
  textSearch: string
  createdAt: string
  updatedAt: string
}

export interface LookupTableVariables {
  sortBy: LookupTableSort
  filter: LookupTableFilter
}
export interface LookupTableData {
  collection: {
    id: string
    value: string
    systemValue: string
    sort: number
    lookupType: string
    createdAt: string
    updatedAt: string
  }[]
  metadata: {
    currentPage: number
    limitValue: number
    totalCount: number
    totalPages: number
  }
}

export const LOOKUP_TABLE_QUERY = gql`
  query lookupTables($sortBy: LookupTableSort, $filter: LookupTableFilter) {
    lookupTables(sortBy: $sortBy, filter: $filter) {
      collection {
        id
        lookupType
        value
        systemValue
        sort
        __typename
      }
    }
  }
`

export const TASKS_BY_USER_QUERY = gql`
  query tasksByUser($sortBy: TasksByUserSort, $filter: TasksByUserFilter, $page: Int, $limit: Int) {
    tasksByUser(sortBy: $sortBy, filter: $filter, page: $page, limit: $limit) {
      collection {
        id
        title
        dueDate
        done
        processingId
        processing {
          caseId
          m2Case {
            policyholder {
              policyNumber
              lastname
              company
            }
            claimNumber
          }
        }
        __typename
      }
      metadata {
        totalCount
        currentPage
      }
    }
  }
`

export const RESULTS_QUERY = gql`
  query results($sortBy: ResultSort, $filter: ResultFilter) {
    results(sortBy: $sortBy, filter: $filter) {
      collection {
        id
        resultType
        title
        systemValue
        __typename
      }
    }
  }
`

export const POOLS_QUERY = gql`
  query pools($sortBy: PoolSort, $filter: PoolFilter) {
    pools(sortBy: $sortBy, filter: $filter) {
      collection {
        id
        name
      }
    }
  }
`

export const DROPDOWN_USER_QUERY = gql`
  query users($sortBy: UserSort, $filter: UserFilter) {
    users(sortBy: $sortBy, filter: $filter) {
      collection {
        id
        altName
        email
        active
        archived
        employeeHohner
      }
    }
  }
`

export const DROPDOWN_PROCESSINGS_QUERY = gql`
  query processings($sortBy: ProcessingSort, $filter: ProcessingFilter) {
    processings(sortBy: $sortBy, filter: $filter) {
      collection {
        id
        processingStep
        assignedProcessingType {
          intern
        }
      }
    }
  }
`

export const POOL_CASES_QUERY = gql`
  query poolProcessings(
    $poolSlug: String!
    $sortBy: PoolProcessingsSort
    $filter: PoolProcessingsFilter
    $page: Int
    $limit: Int
  ) {
    poolProcessings(
      poolSlug: $poolSlug
      sortBy: $sortBy
      filter: $filter
      page: $page
      limit: $limit
    ) {
      metadata {
        totalCount
        currentPage
      }
      collection {
        id
        fileNumber
        poolId
        invoice {
          id
          invoiceNo
          sentAt
        }
        income
        additionalIncome
        invoiceSettlementComments
        costsSettledWithRsvAt
        rsvBillable
        rsvNonBillableReason
        additionalCost
        paymentStatus
        m2Case {
          forwardedCase
          legalAdvice
          coverageScope
          id
          source
          caseState
          commentsPolicyholder
          commentsLegalAdvice
          insurance {
            title
          }
          hint
          creator {
            id
            name
          }
          creatorComments
          project {
            title
            callCenterLegalAdvice
            collisionDetectionEnabled
          }
          policyholder {
            salutation
            lastname
            firstname
            birthDate
            company
            street
            houseNumber
            postalCode
            place
            phoneNumber
            mobileNumber
            email
            comments
            communicationViaCustomerPortal
            casesCount
            policyNumber
            cases {
              id
              claimNumber
              assignedToM2At
            }
          }
          conflictPartner {
            salutation
            lastname
            firstname
            birthDate
            company
            street
            houseNumber
            postalCode
            place
            phoneNumber
            mobileNumber
            email
            comments
          }
          contactPerson {
            id
            salutation
            lastname
            firstname
            phoneNumber
            mobileNumber
            email
            relationToPolicyholder
          }
          claimNumber
          claimDate
          assignedToM2At
          m2LegalArea {
            title
          }
          rsvLegalArea {
            title
          }
          comments
          disputeValue
          deductible
          deductibleAmount
          evaluatedDailyAt
          evaluatedMonthlyAt
          rsvStatus
        }
        coverage
        agentId
        agent {
          name
        }
        assignedFromId
        assignedFrom {
          name
          id
        }
        assignedAt
        acceptedAt
        canBeginAt
        processingStep
        processingState
        firstCallAt
        forwardedToRightmartAt
        closedAt
        closedByM2
        processingComments
        intendedProcessingType {
          title
        }
        assignedProcessingType {
          title
        }
        actualProcessingType {
          id
          title
          callCenterLegalAdvice
        }
        documentsWereSent
        settlementRate
        settlementTypeId
        settlementType {
          title
        }
        closingTypeId
        closingType {
          id
          title
        }
        result
        closedByRsv
        feedbackRsvAt
        closingComments
        coverage
        policyholderContactedAt
        coverageRequestedAt
        forwardedToRightmartAt
        coverageComments
        date1
        date2
        lastActionAt
        nextTaskAt
        nextTaskComment
        canBeginAt
        firstCallDelayed
        goalPolicyholderWants
        goalPolicyholderSolutions
        goalConflictPartnerWants
        goalConflictPartnerSolutions
      }
    }
  }
`

export const CASES_QUERY = gql`
  query processings(
    $sortBy: ProcessingSort
    $filter: ProcessingFilter
    $page: Int
    $limit: Int
    $isAdmin: Boolean = false
    $creator: Boolean
    $months: Int
  ) {
    processings(
      sortBy: $sortBy
      filter: $filter
      page: $page
      limit: $limit
      creator: $creator
      months: $months
    ) {
      collection {
        id
        fileNumber
        poolId
        invoice {
          id
          invoiceNo
          sentAt
        }
        income
        additionalIncome
        invoiceSettlementComments
        costsSettledWithRsvAt
        rsvBillable
        rsvNonBillableReason
        additionalCost
        paymentStatus
        m2Case {
          forwardedCase
          legalAdvice
          coverageScope
          id
          settlementComments @include(if: $isAdmin)
          customerRating @include(if: $isAdmin)
          source
          caseState
          commentsPolicyholder
          commentsLegalAdvice
          insurance {
            title
          }
          hint
          creator {
            id
            name
          }
          creatorComments
          project {
            title
            callCenterLegalAdvice
            collisionDetectionEnabled
          }
          policyholder {
            salutation
            lastname
            firstname
            birthDate
            company
            street
            houseNumber
            postalCode
            place
            phoneNumber
            mobileNumber
            email
            comments
            communicationViaCustomerPortal
            casesCount
            policyNumber
            cases {
              id
              claimNumber
              assignedToM2At
            }
          }
          conflictPartner {
            salutation
            lastname
            firstname
            birthDate
            company
            street
            houseNumber
            postalCode
            place
            phoneNumber
            mobileNumber
            email
            comments
          }
          contactPerson {
            id
            salutation
            lastname
            firstname
            phoneNumber
            mobileNumber
            email
            relationToPolicyholder
          }
          claimNumber
          claimDate
          assignedToM2At
          m2LegalArea {
            title
          }
          rsvLegalArea {
            title
          }
          comments
          cost @include(if: $isAdmin)
          additionalCost @include(if: $isAdmin)
          customerComments @include(if: $isAdmin)
          disputeValue
          deductible
          deductibleAmount
          costsSettledWithRsvAt @include(if: $isAdmin)
          evaluatedDailyAt
          evaluatedMonthlyAt
          rsvStatus
          statusReportSentAt @include(if: $isAdmin)
        }
        coverage
        agentId
        agent {
          name
          role @include(if: $isAdmin)
        }
        assignedFromId
        assignedFrom {
          name
          id
        }
        assignedAt
        acceptedAt
        canBeginAt
        processingStep
        processingState
        firstCallAt
        forwardedToRightmartAt
        closedAt
        closedByM2
        processingComments
        intendedProcessingType {
          title
        }
        assignedProcessingType {
          title
        }
        actualProcessingType {
          id
          title
          callCenterLegalAdvice
        }
        documentsWereSent
        settlementRate
        settlementTypeId
        settlementType {
          title
        }
        closingTypeId
        closingType {
          title
        }
        result
        closedByRsv
        comments @include(if: $isAdmin)
        additionalComments @include(if: $isAdmin)
        feedback @include(if: $isAdmin)
        feedbackChannel @include(if: $isAdmin)
        feedbackClosedAt @include(if: $isAdmin)
        receivedFeedbackAt @include(if: $isAdmin)
        feedbackRsvAt
        costsSettledWithOperatorAt @include(if: $isAdmin)
        cost @include(if: $isAdmin)
        additionalCost @include(if: $isAdmin)
        costsCoveredByKp @include(if: $isAdmin)
        receivedPayment @include(if: $isAdmin)
        settlementComments @include(if: $isAdmin)
        closingComments
        coverage
        policyholderContactedAt
        coverageRequestedAt
        forwardedToRightmartAt
        coverageComments
        date1
        date2
        statusReportSentAt @include(if: $isAdmin)
        lastActionAt
        nextTaskAt
        nextTaskComment
        canBeginAt
        firstCallDelayed
        goalPolicyholderWants
        goalPolicyholderSolutions
        goalConflictPartnerWants
        goalConflictPartnerSolutions
      }
      metadata {
        totalCount
        currentPage
      }
    }
  }
`

export const PROJECTS_QUERY = gql`
  query projects($sortBy: ProjectSort, $filter: ProjectFilter, $page: Int, $limit: Int) {
    projects(sortBy: $sortBy, filter: $filter, page: $page, limit: $limit) {
      collection {
        id
        title
        interface
        archived
        collisionDetectionEnabled
        clientPayment
      }
      metadata {
        totalCount
      }
    }
  }
`

export const INSURANCE_QUERY = gql`
  query insurances($sortBy: InsuranceSort, $filter: InsuranceFilter, $page: Int, $limit: Int) {
    insurances(sortBy: $sortBy, filter: $filter, page: $page, limit: $limit) {
      collection {
        id
        title
        policyNumberRegex
      }
      metadata {
        totalCount
      }
    }
  }
`

export const RSV_LEGAL_AREA_QUERY = gql`
  query rsvLegalAreas(
    $sortBy: RsvLegalAreaSort
    $filter: RsvLegalAreaFilter
    $page: Int
    $limit: Int
  ) {
    rsvLegalAreas(sortBy: $sortBy, filter: $filter, page: $page, limit: $limit) {
      collection {
        id
        title
        archived
      }
      metadata {
        totalCount
      }
    }
  }
`

export const M2_LEGAL_AREA_QUERY = gql`
  query m2LegalAreas(
    $sortBy: M2LegalAreaSort
    $filter: M2LegalAreaFilter
    $page: Int
    $limit: Int
  ) {
    m2LegalAreas(sortBy: $sortBy, filter: $filter, page: $page, limit: $limit) {
      collection {
        id
        title
        archived
      }
      metadata {
        totalCount
      }
    }
  }
`

export const DROPDOWN_PROCESSING_TYPES_QUERY = gql`
  query processingTypes(
    $sortBy: ProcessingTypeSort
    $filter: ProcessingTypeFilter
    $page: Int
    $limit: Int
  ) {
    processingTypes(sortBy: $sortBy, filter: $filter, page: $page, limit: $limit) {
      collection {
        id
        title
        closeableOnFirstCall
        legalAdviceOnly
        callCenterLegalAdvice
        archived
        accessibleFor
      }
      metadata {
        totalCount
      }
    }
  }
`
export const PROCESSING_TYPES_QUERY = gql`
  query processingTypes(
    $sortBy: ProcessingTypeSort
    $filter: ProcessingTypeFilter
    $page: Int
    $limit: Int
  ) {
    processingTypes(sortBy: $sortBy, filter: $filter, page: $page, limit: $limit) {
      collection {
        id
        projectId
        title
        cost
        accessibleFor
      }
      metadata {
        totalCount
      }
    }
  }
`
export const CASE_QUERY = gql`
  query m2Case($id: ID!, $isAdmin: Boolean = false) {
    m2Case(id: $id) {
      forwardedCase
      coverageScope
      id
      createdAt
      source
      documents {
        url
        filename
        contentType
        pageCount
      }
      hint
      commentsPolicyholder
      commentsLegalAdvice
      insuranceId
      insurance {
        title
        policyNumberRegex
      }
      projectId
      invoices {
        id
        invoiceNo
        sentAt
        documentUrl
        processing {
          id
          costsSettledWithRsvAt
          rsvBillable
          rsvNonBillableReason
          cost
          costsCoveredByKp
          receivedPayment
          additionalCost
          income
          additionalIncome
          processingStep
        }
      }
      project {
        title
        smsFromName
        smsText
        requestCostCoverageAvailable
        rightmartForwardingAvailable
        costCoverageEmail
        interface
        invoicingAfterFirstCall
        closeableOnFirstCall
        callCenterLegalAdvice
        collisionDetectionEnabled
        clientPayment
      }
      caseState
      rsvStatus
      policyholder {
        id
        policyNumber
        salutation
        lastname
        firstname
        birthDate
        company
        street
        houseNumber
        postalCode
        place
        country
        phoneNumber
        mobileNumber
        email
        comments
        communicationViaCustomerPortal
        lastSignInAt
        firstSignInAt
        cases {
          id
          claimNumber
          assignedToM2At
        }
        casesWithSameEmail {
          id
          claimNumber
          assignedToM2At
        }
      }
      conflictPartner {
        id
        salutation
        lastname
        firstname
        birthDate
        company
        street
        houseNumber
        postalCode
        place
        country
        phoneNumber
        mobileNumber
        email
        comments
      }
      contactPerson {
        salutation
        id
        lastname
        firstname
        phoneNumber
        mobileNumber
        email
        relationToPolicyholder
      }
      claimNumber
      claimDate
      assignedToM2At
      m2LegalAreaId
      rsvLegalAreaId
      disputeValue
      creatorComments
      creatorId
      creator {
        id
        name
      }
      cost @include(if: $isAdmin)
      additionalCost @include(if: $isAdmin)
      comments
      customerComments @include(if: $isAdmin)
      settlementComments @include(if: $isAdmin)
      customerRating @include(if: $isAdmin)
      deductible
      deductibleAmount
      costsSettledWithRsvAt @include(if: $isAdmin)
      evaluatedDailyAt
      evaluatedMonthlyAt
      statusReportSentAt @include(if: $isAdmin)
      smsLogs
      paymentContacts {
        contactType
        salutation
        firstname
        lastname
        email
      }
      processings {
        poolId
        fileNumber
        deletable
        assignedFromId
        assignedFrom {
          id
          name
        }
        invoice @include(if: $isAdmin) {
          id
          invoiceNo
          sentAt
          documentUrl
        }
        income
        totalPayments
        totalRefunds
        totalAmount
        refundAmount
        refundRequired
        payments {
          id
          amount
          amountDecimal
          refundAmount
          refundAmountDecimal
          processingId
          email
          status
          refundStatus
          stripeCheckoutUrl
          paymentRequestedAt
          paymentReceivedAt
          reminderSentAt
          firstname
          lastname
          salutation
          createdAt
          updatedAt
        }
        paymentProtocols {
          id
          processingId
          activityType
          activityTypeLabel
          amountNetto
          amountBrutto
          fullname
          email
          createdAt
        }
        additionalIncome
        invoiceSettlementComments
        costsSettledWithRsvAt
        rsvBillable
        rsvNonBillableReason
        additionalCost
        id
        coverage
        processingState
        costFromActualProcessingType
        agentId
        agent {
          id
          name
          birthDate
          houseNumber
          place
          postalCode
          street
        }
        involvedUsers {
          id
          name
        }
        assignedAt
        canBeginAt
        acceptedAt
        firstCallAt
        forwardedToRightmartAt
        closedAt
        closedByM2
        intendedProcessingTypeId
        documentsWereSent
        settlementRate
        settlementTypeId
        closingTypeId
        result
        closedByRsv
        alternativeProcess
        comments @include(if: $isAdmin)
        coverageComments
        feedback @include(if: $isAdmin)
        feedbackChannel @include(if: $isAdmin)
        feedbackClosedAt @include(if: $isAdmin)
        receivedFeedbackAt @include(if: $isAdmin)
        costsSettledWithOperatorAt @include(if: $isAdmin)
        cost @include(if: $isAdmin)
        additionalCost @include(if: $isAdmin)
        costsCoveredByKp @include(if: $isAdmin)
        receivedPayment @include(if: $isAdmin)
        settlementComments @include(if: $isAdmin)
        date1
        date2
        coverageRequestedAt
        feedbackRsvAt
        policyholderContactedAt
        closingComments
        actualProcessingType {
          callCenterLegalAdvice
        }
        actualProcessingTypeId
        assignedProcessingTypeId
        assignedProcessingType {
          title
          intern
          accessibleFor
        }
        additionalComments @include(if: $isAdmin)
        statusReportSentAt @include(if: $isAdmin)
        lastActionAt
        nextTaskAt
        nextTaskComment
        canBeginAt
        firstCallDelayed
        forwardedToRightmartAt
        goalPolicyholderWants
        goalPolicyholderSolutions
        goalConflictPartnerWants
        goalConflictPartnerSolutions
        processingComments
        contactAttemptSmsLogs
        m2Case {
          forwardedCase
          claimDate
          comments
          hint
          insuranceId
          insurance {
            title
          }
          projectId
          project {
            closeableOnFirstCall
            callCenterLegalAdvice
            collisionDetectionEnabled
          }
          policyholder {
            policyNumber
            firstname
            lastname
            email
            phoneNumber
            mobileNumber
            lastSignInAt
            communicationViaCustomerPortal
            postalCode
            place
          }
          conflictPartner {
            firstname
            lastname
            email
            phoneNumber
            mobileNumber
            postalCode
            place
            street
            houseNumber
            company
            salutation
          }
          conflictPartnerNotNamed
          contactPerson {
            firstname
            lastname
            email
            phoneNumber
            mobileNumber
            relationToPolicyholder
          }
          m2LegalArea {
            title
            costsCoveredByKpActivePerDefault
          }
        }
      }
    }
  }
`

export const EXPORT_PROCESSINGS_QUERY = gql`
  query exportProcessings(
    $format: ExportTypeEnum
    $filter: ProcessingFilter
    $sortBy: ProcessingSort
    $ids: [ID!]
    $columns: [ExportColumn!]
  ) {
    exportProcessings(
      format: $format
      filter: $filter
      sortBy: $sortBy
      ids: $ids
      columns: $columns
    )
  }
`

export const PROCESSINGS_EXPORT_TASKS_QUERY = gql`
  query processingsExportTasks(
    $sortBy: ProcessingsExportTaskSort
    $filter: ProcessingsExportTaskFilter
    $limit: Int
  ) {
    processingsExportTasks(sortBy: $sortBy, filter: $filter, limit: $limit) {
      collection {
        id
        createdAt
        hasDownloaded
        documentUrl
        hasErrors
        userId
        status
      }
    }
  }
`

export const COLLISION_DETECTION_QUERY = gql`
  query collisionDetectionQuery($firstname: String!, $lastname: String!, $postalCode: String!) {
    m2CasesByCollisionDetection(
      firstname: $firstname
      lastname: $lastname
      postalCode: $postalCode
    ) {
      id
      comments
      claimNumber
      claimDate
      m2LegalArea {
        id
        title
      }
    }
  }
`

export const CHART1_1_QUERY = gql`
  query m2CasesTotalCount($groupBy: M2CaseGroupBy, $filter: M2CaseStatisticFilter) {
    m2CasesTotalCount(groupBy: $groupBy, filter: $filter) {
      series {
        color
        title
        casesAverage
        serie {
          name
          date
          count
        }
      }
    }
  }
`

export const CHART1_ON_CASE_STATE_QUERY = gql`
  query m2CasesOnCaseStateTotalCount(
    $groupBy: M2CaseOnCaseStateGroupBy
    $filter: M2CaseOnCaseStateStatisticFilter
  ) {
    m2CasesOnCaseStateTotalCount(groupBy: $groupBy, filter: $filter) {
      series {
        color
        title
        casesAverage
        serie {
          name
          date
          count
        }
      }
    }
  }
`

export const CHART1_2_QUERY = gql`
  query processingsTotalCount(
    $filter: ProcessingsTotalCountFilter
    $groupBy: ProcessingsTotalCountGroupBy
  ) {
    processingsTotalCount(filter: $filter, groupBy: $groupBy) {
      series {
        color
        title
        usersAverage
        serie {
          name
          date
          count
        }
      }
    }
  }
`

export const CHART2_QUERY = gql`
  query processingsFinishQuote(
    $groupBy: ProcessingsFinishQuoteGroupBy
    $filter: ProcessingsFinishQuoteFilter
    $target: Boolean
    $intern: Boolean
    $extern: Boolean
    $allAgents: Boolean
    $min: Int
    $max: Int
  ) {
    processingsFinishQuote(
      groupBy: $groupBy
      filter: $filter
      target: $target
      intern: $intern
      extern: $extern
      allAgents: $allAgents
      min: $min
      max: $max
    ) {
      series {
        color
        title
        average
        serie {
          name
          count
        }
      }
    }
  }
`

export const CHART3_QUERY = gql`
  query processingsClosedCountByProcessingTypes(
    $filter: ProcessingsClosedCountByProcessingTypesFilter
    $groupBy: ProcessingsClosedCountByProcessingTypesGroupBy
  ) {
    processingsClosedCountByProcessingTypes(filter: $filter, groupBy: $groupBy) {
      series {
        color
        title
        usersAverage
        processingTypeAverage
        serie {
          name
          date
          count
        }
      }
    }
  }
`

export const CHART4_QUERY = gql`
  query processingsClosedCount(
    $showAgents: Boolean
    $filter: ProcessingsClosedCountFilter
    $groupBy: ProcessingsClosedCountGroupBy
  ) {
    processingsClosedCount(showAgents: $showAgents, filter: $filter, groupBy: $groupBy) {
      series {
        color
        title
        usersAverage
        processingTypeAverage
        serie {
          name
          date
          count
        }
      }
    }
  }
`

export const CHART5_QUERY = gql`
  query processingsFeedbackQuote(
    $groupBy: ProcessingsFeedbackQuoteGroupBy
    $filter: ProcessingFeedbackQuoteFilter
  ) {
    processingsFeedbackQuote(groupBy: $groupBy, filter: $filter) {
      series {
        color
        title
        total
        average
        stack
        serie {
          name
          count
          total
          percentage
        }
      }
    }
  }
`

export const SELECTIONS_QUERY = gql`
  query columnSelections($shared: Boolean) {
    columnSelections(shared: $shared) {
      collection {
        id
        title
        columns
        sharedWith
        user {
          name
        }
      }
    }
  }
`

export const SETTLEMENT_TYPES_QUERY = gql`
  query settlementTypes($sortBy: SettlementTypeSort, $filter: SettlementTypeFilter) {
    settlementTypes(sortBy: $sortBy, filter: $filter) {
      collection {
        id
        title
      }
    }
  }
`

export const CLOSING_TYPES_QUERY = gql`
  query closingTypes($sortBy: ClosingTypeSort, $filter: ClosingTypeFilter) {
    closingTypes(sortBy: $sortBy, filter: $filter) {
      collection {
        id
        title
      }
    }
  }
`

export const USER_STATS_QUERY = gql`
  query userStats($isLegalAdvisor: Boolean = false, $isAdmin: Boolean = false) {
    userStats {
      acceptedCases @skip(if: $isAdmin) {
        category
        level
        message
      }
      firstCalls @skip(if: $isAdmin) {
        category
        level
        message
      }
      reminders @skip(if: $isAdmin) {
        category
        level
        message
      }
      pools {
        category
        level
        message
      }
      assignedLegalAdvices @include(if: $isLegalAdvisor) {
        category
        level
        message
      }
      acceptedLegalAdvices @include(if: $isLegalAdvisor) {
        category
        level
        message
      }
    }
  }
`
export const ACTIONS_QUERY = gql`
  query actions(
    $processingId: ID!
    $filter: ActionFilter
    $sortBy: ActionSort
    $limit: Int
    $page: Int
  ) {
    actions(
      processingId: $processingId
      filter: $filter
      sortBy: $sortBy
      limit: $limit
      page: $page
    ) {
      collection {
        hint
        disputeValue
        claimDate
        fileNumber
        closingResult
        closingTypeId
        closingTypeOther
        communicationViaCustomerPortal
        rightActualProcessingType
        rsvLegalArea {
          id
          title
        }
        rsvLegalAreaId
        settlementComments
        actualProcessingType {
          id
          title
          legalAdviceOnly
        }
        actualProcessingTypeId
        intendedProcessingType {
          id
          title
        }
        intendedProcessingTypeId
        contactPersonFirstname
        contactPersonLastname
        contactPersonEmail
        contactPersonPhoneNumber
        contactPersonMobileNumber
        contactPersonRelationToPolicyholder
        policyholderFirstname
        policyholderLastname
        policyholderEmail
        policyholderPlace
        policyholderPostalCode
        policyholderPhoneNumber
        policyholderMobileNumber
        policyNumber
        conflictPartnerCompany
        conflictPartnerSalutation
        conflictPartnerFirstname
        conflictPartnerLastname
        conflictPartnerEmail
        conflictPartnerPhoneNumber
        conflictPartnerPlace
        conflictPartnerStreet
        conflictPartnerHouseNumber
        conflictPartnerMobileNumber
        needsCollisionDetection
        noCollisionByHuman
        completeReason
        conflictPartnerAgreesMediation
        conflictPartnerAgreesToCoverCosts
        contactSuccessful
        contactType
        costs
        costsCoveredByKp
        createdAt
        customerComments
        customerRating
        customerMessageType
        dataset
        date
        firstCall
        id
        informReason
        internalTransferReason
        note
        legalAdvice
        mailSentAt
        attorneyStatus
        noMailSentReason
        attorneyReason
        attorneyType
        policyholderAgreesMediation
        processing {
          agentId
          id
          m2Case {
            id
          }
          involvedUsers {
            id
            name
          }
        }
        payment {
          id
          amount
          amountDecimal
          email
          salutation
          firstname
          lastname
          status
          stripeCheckoutUrl
          paymentRequestedAt
          paymentReceivedAt
          createdAt
          updatedAt
          user {
            email
            firstname
            lastname
          }
        }
        processingId
        type
        updatedAt
        user {
          id
          name
          role
        }
        userId
        visibleForOtherProcessings
        visibleForPolicyholder
        uploads {
          id
          attachmentUrl
          attachmentFilename
        }
      }
    }
  }
`

export const BLOG_POSTS_QUERY = gql`
  query blogPosts($filter: BlogPostFilter, $sortBy: BlogPostSort, $limit: Int, $page: Int) {
    blogPosts(filter: $filter, sortBy: $sortBy, limit: $limit, page: $page) {
      collection {
        id
        title
        subtitle
        pinned
        content
        createdAt
        updatedAt
        categories
      }
    }
  }
`
export const BLOG_POST_QUERY = gql`
  query blogPost($id: ID!) {
    blogPost(id: $id) {
      id
      title
      subtitle
      pinned
      content
      createdAt
      updatedAt
      categories
    }
  }
`

export const POLICY_NUMBER_QUERY = gql`
  query policyNumber($policyNumber: String!, $insuranceId: ID!) {
    policyNumber(policyNumber: $policyNumber, insuranceId: $insuranceId) {
      id
      policyNumber
    }
  }
`

export const POLICY_NUMBER_VALID_QUERY = gql`
  query PolicyNumberValid($insuranceId: ID!, $policyNumber: String!) {
    policyNumberValid(insuranceId: $insuranceId, policyNumber: $policyNumber)
  }
`
export const PROCESSINGS_EVALUATION_QUERY = gql`
  query processingsEvaluation($filter: ProcessingsEvaluationFilter) {
    processingsEvaluation(filter: $filter) {
      series {
        name
        openProcessings
        legalProcessingsSum
        legalProcessingsResolvedByLegalAdvisor
        legalProcessingsReferredToM2Med
        legalProcessingsReferredToM2Zmb
        legalProcessingsReferredAttorneyArag
        legalProcessingsReferredAttorneyAuxilia
        legalProcessingsReferredAttorneyAllianz
        legalProcessingsToRightmartAuxilia
        clickPathProcessingsSum
        clickPathProcessingsResolvedByLegalAdvisor
        clickPathProcessingsReferredToM2Med
        clickPathProcessingsReferredToM2Zmb
        clickPathProcessingsReferredAttorneyArag
        clickPathProcessingsReferredAttorneyAuxilia
        clickPathProcessingsReferredAttorneyAllianz
        clickPathProcessingsToRightmartAuxilia
        serviceProcessings
      }
    }
  }
`

export const COMPANIES_QUERY = gql`
  query companies($sortBy: CompanySort, $filter: CompanyFilter, $page: Int, $limit: Int) {
    companies(sortBy: $sortBy, filter: $filter, page: $page, limit: $limit) {
      collection {
        id
        company
        title
        street
        houseNumber
        postalCode
        place
        country
        email
        phoneNumber
        mobileNumber
      }
      metadata {
        totalCount
      }
    }
  }
`
